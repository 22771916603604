<template>
  <v-layout class="mb-4 pb-4" wrap align-start row>
    <v-flex xs12>
      <Title title="Globales"></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-layout class="py-2 px-2 border-bottom" wrap align-start row>
            <v-flex xs12 sm4 class="body-2">
              Texto Automatico MessageBird
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              message_bird_text
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Dias envio de cupones
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              date_important_days
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Minimo de compra cupones
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              date_important_min
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Descuento en los cupones
            </v-flex>
            <v-flex xs12 sm8 class="pl-3">
              date_important_dscto
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="update">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Title from '../useful/title.vue'

export default {
  name: 'SettingGlobal',
  components: {
    Title
  },
  props: ['config', 'user'],
  data() {
    return {
      loading: false,
      dateContest: null,
      activeContest: false,
      productID: false,
      modal: false,
      sounds: [
        { id: 'to-the-point.mp3', name: 'to-the-point.mp3' },
        { id: 'stairs.mp3', name: 'stairs.mp3' },
        { id: 'exquisite.mp3', name: 'exquisite.mp3' },
        { id: 'ding.mp3', name: 'ding.mp3' },
        { id: 'definite.mp3', name: 'definite.mp3' },
        { id: 'coins.mp3', name: 'coins.mp3' },
        { id: 'appointed.mp3', name: 'appointed.mp3' }
      ],
      listPages: [
        { id: 'index', name: 'Inicio' },
        { id: 'pedidos', name: 'Pedidos' },
        { id: 'ordersMap', name: 'Mapa' },
        { id: 'cashDesk', name: 'Caja' }
      ]
    }
  },
  computed: {
    dark: {
      get() {
        const dark = this.$store.getters.getDark
        return dark
      },
      set(value) {
        this.$store.dispatch('changeDark', { dark: value })
        this.$vuetify.theme = this.$store.getters.getTheme
      }
    },
    colorPrimary: {
      get() {
        const theme = this.$store.getters.getTheme
        return theme.primary
      },
      set(value) {
        this.$store.dispatch('setColorPrimary', value)
        this.$vuetify.theme = this.$store.getters.getTheme
      }
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    playSound(e) {
      const audio = new Audio(`${process.env.BASE_URL}sound/${this.settings[e]}`)
      audio.play()
    },
    update() {
      this.loading = true
      setTimeout(() => {
        this.$store.dispatch('setSettings', this.settings)
        this.loading = false
      }, 1000)
    },
    get() {
      this.dateContest = this.config.COMPETITION_POLL_DATE
      // eslint-disable-next-line eqeqeq
      this.activeContest = this.config.COMPETITION_POLL == 'true' || this.config.COMPETITION_POLL == '1'
      this.productID = this.config.COMPETITION_POLL_PRODUCT
    }
  }
}
</script>
